// src/store/notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [{
    "id": "id",
    "message": "Welcome To IronEdge",
    "type": "info",
    "details": "Stay Tuned For Some Big Updates !!!"
  }],
  showNotificationPanel: false,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const { id, message, type, details } = action.payload;
      state.notifications.push({ id, message, type, details });
    },
    removeNotification: (state, action) => {
      const { id } = action.payload;
      state.notifications = state.notifications.filter(n => n.id !== id);
    },
    toggleNotificationPanel: (state) => {
      state.showNotificationPanel = !state.showNotificationPanel;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const { addNotification, removeNotification, toggleNotificationPanel, clearNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
