import React from 'react';
import useConfig from '../../hooks/useConfig'

const Footer = () => {
  const config = useConfig();

  if (!config) return null;

  return (
    <footer className="bg-gray-900 text-gray-400 py-6 mt-auto">
      <div className="container mx-auto text-center">
        <p>&copy; {new Date().getFullYear()} {config.footer.companyName}. All rights reserved.</p>
        <div className="space-x-4 mt-2">
          {config.footer.links.map((link, index) => (
            <a key={index} href={link.url} className="hover:text-white">
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
