// src/store/brokerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const brokerSlice = createSlice({
  name: 'brokers',
  initialState: {
    brokers: []
  },
  reducers: {
    setBrokers: (state, action) => {
      state.brokers = action.payload;
    },
    updateBroker: (state, action) => {
      const { name, updates } = action.payload;
      const broker = state.brokers.find(b => b.name === name);
      console.log("find broker", broker)
      if (broker) {
        // Update each field in the fields array
        broker.fields.forEach(field => {
          if (updates[field.key] !== undefined) {
            field.value = updates[field.key];
          }
        });
      }
    }
  }
});

export const { setBrokers, updateBroker } = brokerSlice.actions;
export default brokerSlice.reducer;
