// src/store/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import notificationReducer from './notificationSlice';
import brokerReducer from './brokerSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationReducer,
  brokers: brokerReducer,
});

export default rootReducer;
