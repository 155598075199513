// src/hooks/useConfig.js
import { useState, useEffect } from 'react';

const useConfig = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json'); // Access from public directory
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setConfig(data);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };

    fetchConfig();
  }, []);

  return config;
};

export default useConfig;
