import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      console.log("Login action dispatched"); // Debug log
      state.isAuthenticated = true;
      state.user = action.payload; // Store user data if needed
      state.access_token = action.payload.access_token; // Store access token in a variable
      localStorage.setItem('user', JSON.stringify(action.payload)); // Store user data in local storage
      localStorage.setItem('access_token', action.payload.access_token); // Store user data in local storage
    },
    logout: (state) => {
      state.isAuthenticated = false;
     
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
